import { useEffect, useState } from 'react';
import logo from '../src/logomx.png';
import './App.css';
import * as Form from '@radix-ui/react-form';
import '../src/styles/styles.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';

function App() {
  const [coberturas, setCoberturas] = useState([]);
  const [productos, setProductos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCurrentDiv, setShowCurrentDiv] = useState(true);
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [productosTexto, setProductosTexto] = useState('');
  const [filasSeleccionadas, setFilasSeleccionadas] = useState({});
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");
  const [productosMostrados, setProductosMostrados] = useState(new Set());

  const handleCategoriaChange = (e) => {
    setCategoriaSeleccionada(e.target.value);
  };

  const opciones = [
    'ANIMALES',
    'ACCESORIOS PARA ANIMALES',
    'ALIMENTO PARA ANIMALES',
    'ACCESORIOS DE CUERO',
    'HERRAMIENTAS',
    'SEMILLAS - GRANOS',
    'PLANTAS',
    'ABONO PARA PLANTAS',
    'HERBICIDAS',
    'MAQUINARIA',
    'MOTOCICLETAS - SCOOTER - CUATRIMOTO',
    'BICICLETAS / TRICICLOS / MONOCICLOS',
    'SUPLEMENTO ALIMENTICIO',
    'EQUIPO DE SONIDO',
    'ROPA',
    'GUANTES',
    'EQUIPO MEDICO',
    'MATERIAL MÉDICO',
    'ARTICULOS DE LIMPIEZA',
    'EQUIPO DE COMPUTO COMPUTADORA',
    'ACCESORIOS PARA COMPUTADORA',
    'EQUIPO DE COMPUTO',
    'CELULAR TELEFONO',
    'CELULAR TELEFONO FUNDA DE CELULAR CARGADOR DE CELULAR',
    'VIDEOJUEGOS',
    'CALZADO - ZAPATOS',
    'EQUIPO DE LIMPIEZA',
    'MÁQUINAS DE JUEGO',
    'JUEGOS',
    'JUEGOS DE APUESTAS',
    'CIGARROS TABACO ACCESORIOS PARA CIGARRO CIGARRILLOS',
    'SUPLEMENTO ALIMENTICIO VITAMINAS',
    'CORTINAS',
    'TELEVISOR TV TELEVISION PANTALLA REPRODUCTOR ESTEREO',
    'ACCESORIOS DE ELECTRONICOS EQUIPO AUDIOVISUAL',
    'ACCESORIOS PARA ROPA',
    'ROPA UNIFORMES',
    'TRAJES DE BAÑO BIKINI',
    'ROPA DEPORTIVA',
    'CALZADO ZAPATOS BOTAS',
    'CALZADO SANDALIAS HUARACHE',
    'CALZADO - ZAPATOS - TENNIS',
    'ACCESORIOS PARA CALZADO',
    'MALETAS',
    'BOLSAS BOLSOS MONEDEROS',
    'CARTERAS',
    'ACCESORIOS PARA VIAJE',
    'MATERIAL DENTAL',
    'MAQUILLAJE LOCION PERFUME SHAMPOO CHAMPU CREMAS',
    'TATUAJE',
    'JOYERIA',
    'JOYERIA BISUTERIA',
    'RELOJES PULSERAS',
    'RELOJES DE PARED',
    'ARTICULOS DE HOGAR',
    'PIEDRAS PRECIOSAS',
    'PERLAS',
    'DOCUMENTOS',
    'MUEBLES MUEBLERIA',
    'ASIENTOS MUEBLES',
    'ESCRITORIOS MUEBLES DE OFICINA',
    'MOBILIARIO',
    'INTRUMENTOS DE LABORATORIO',
    'PAPELERIA',
    'ACCESORIOS MUSICALES',
    'INSTRUMENTOS MUSICALES',
    'JUGUETES',
    'ACCESORIOS DE JUEGOS',
    'SERVICIOS DE LIMPIEZA',
    'ESTABLECIMIENTOS DE JUEGOS',
    'CIGARROS TABACO',
  ];

  const opcionesOrdenadas = opciones.sort((a, b) => {
    return a.localeCompare(b);
  });


  const handleConsultarCobertura = async (codigoPostal) => {
    try {
      setLoading(true);
      //const response = await fetch(`http://localhost:1339/api/cobertura/${codigoPostal}`, {
      const response = await fetch(`https://dev-dattmx.com/api/cobertura/${codigoPostal}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (response.status == 404) {
        setCoberturas("Error");
      } else {
        setCoberturas(data);
      }
      //console.log(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConsultarProductos = async () => {
    try {
      setLoading(true);
      //const response = await fetch(`http://localhost:1339/api/consulta/${categoriaSeleccionada}`, {
      const response = await fetch(`https://dev-dattmx.com/api/consulta/${categoriaSeleccionada}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      if (response.status == 404) {
        setProductos("Error");
      } else {
        const productosUnicos = [];
        const codigosVistos = new Set();
        data.forEach(producto => {
          if (!codigosVistos.has(producto.Codigo)) {
            productosUnicos.push(producto);
            codigosVistos.add(producto.Codigo);
          }
        });
        setProductos(productosUnicos);
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleConsultarProductosSAT = () => {
    setShowCurrentDiv(false);
  };

  const handleConsultarCoberturas = () => {
    setShowCurrentDiv(true);
  };

  const handleRowClick = (producto) => {
    const productoExistente = productosSeleccionados.find((p) => p.Codigo === producto.Codigo);
    if (!productoExistente) {
      setProductosSeleccionados(prevProductos => [...prevProductos, producto]);
      setFilasSeleccionadas(prevFilas => ({ ...prevFilas, [producto.Codigo]: true }));
    }
  };

  useEffect(() => {
    const textoActualizado = productosSeleccionados.map((p) => p.Descripcion).join('0');
    setProductosTexto(textoActualizado);
  }, [productosSeleccionados]);

  const handleInputClick = () => {
    document.getElementById('productosInput').select();
    document.execCommand('copy');
    alert("Texto copiado en el portapapeles, por favor, pegar en CONTENIDO DEL ENVÍO.")
  };

  const handleInputClean = () => {
    setProductosTexto("");
    setProductosSeleccionados([]);
    setFilasSeleccionadas({})
  };

  return (
    <div>{showCurrentDiv ? (
      <div className='Aplicacion' style={{ alignItems: "center" }}>
        <img src={logo} alt="Logo MX" style={{ width: "500px", margin: "60px" }} />
        <button className="Button" style={{ width: "500px", backgroundColor: "#7f7f81" }} onClick={handleConsultarProductosSAT}>Códigos de productos SAT</button>
        <p>
          ¡Consulta las coberturas en todo México!
        </p>
        <Form.Root className="FormRoot">
          <Form.Field className="FormField" name="email">
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
              <Form.Label className="FormLabel">Colonia o código postal</Form.Label>
              <Form.Message className="FormMessage" match="valueMissing">
                Ingresa un valor
              </Form.Message>
            </div>
            <Form.Control asChild>
              <input className="Input" type="email" required />
            </Form.Control>
          </Form.Field>
          <Form.Submit asChild>
            <button className="Button" style={{ marginTop: 10 }} onClick={async () => {
              const codigoPostal = document.querySelector('.Input').value;
              await handleConsultarCobertura(codigoPostal);
            }}>
              Consultar cobertura
            </button>
          </Form.Submit>
        </Form.Root>

        {loading ? (
          <p>Cargando...</p>
        ) : (
          coberturas !== "Error" && coberturas.length > 0 ? (
            <div className="card" style={{ marginTop: '50px', marginBottom: '100px' }}>
              <DataTable
                value={coberturas.sort((a, b) => a.Prioridad - b.Prioridad)}
                showGridlines
                scrollable
                stripedRows
                tableStyle={{ minWidth: '70rem', fontSize: "1.2rem" }}
              >
                <Column field="CP" header="Código Postal" />
                <Column field="Ciudad" header="Municipio/Estado" />
                <Column field="Colonia" header="Localidad/Colonia" />
                <Column field="OL" header="Paquetería" />
                <Column field="Estatus" header="Estatus" body={(rowData) => rowData.Estatus == 0 ? 'Comando' : 'Swift-Label'} />
              </DataTable>
            </div>
          ) : null
        )}

        {/* Mensaje cuando no hay cobertura */}
        {!loading && coberturas === "Error" && (
          <p>No hay cobertura disponible para el código postal/colonia ingresada.</p>
        )}

      </div>
    ) : (

      <div>
        {/* Contenido para consultar productos del SAT */}
        <div className='Aplicacion' style={{ alignItems: "center" }}>
          <img src={logo} alt="Logo MX" style={{ width: "500px", margin: "100px" }} />
          <button className="Button" style={{ width: "500px", backgroundColor: "#7f7f81" }} onClick={handleConsultarCoberturas}>Consulta de coberturas en DATT MX</button>
          <p>
            ¡Consulta los códigos de productos del SAT!
          </p>
          <div>
            <input
              list="categorias"
              className="Input"
              style={{ backgroundColor: '#282c34', marginBottom: "5px" }}
              value={categoriaSeleccionada}
              onChange={handleCategoriaChange}
            />
            <datalist id="categorias">
              {opcionesOrdenadas.map((opcion, index) => (
                <option key={index} value={opcion} />
              ))}
            </datalist>

            <button
              className="Button"
              style={{ marginTop: 10 }}
              onClick={handleConsultarProductos}
            >
              Consultar producto
            </button>
          </div>


          {loading ? (
            <p>Cargando...</p>
          ) : (
            productos !== "Error" && productos.length > 0 ? (
              <div className="card" style={{ marginTop: '50px', marginBottom: '100px', marginLeft: "20px", marginBottom: "50px" }}>
                <input id="productosInput" className="Input" type="text" value={productosTexto} readOnly placeholder='Productos seleccionados' style={{ width: "90%" }} />
                <button className='Button' onClick={handleInputClick} style={{ marginBottom: "50px", width: "90%" }} >Copiar en portapapeles</button>
                <button className='Button' onClick={handleInputClean} style={{ marginBottom: "50px", width: "90%" }} >Limpiar</button>
                <DataTable
                  value={productos}
                  showGridlines
                  scrollable
                  stripedRows
                  tableStyle={{ minWidth: '59rem' }}
                >
                  <Column field="Codigo" header="Código de producto" />
                  <Column field="Descripcion" header="Descripción" />
                  <Column field="Seleccionar" header="Seleccionar producto" body={(rowData) => (
                    <button
                      className='Button'
                      onClick={() => handleRowClick(rowData)}
                      style={{ backgroundColor: filasSeleccionadas[rowData.Codigo] ? 'green' : '' }}
                    >
                      {filasSeleccionadas[rowData.Codigo] ? 'Seleccionado' : 'Seleccionar'}
                    </button>
                  )} />
                </DataTable>
              </div>
            ) : null
          )}

          {/* Mensaje cuando no hay cobertura */}
          {!loading && productos === "Error" && (
            <p>No existe el producto ingresado.</p>
          )}

        </div>

      </div>
    )}
    </div>
  );
}

export default App;
